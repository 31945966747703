import { Field, useFormikContext } from 'formik'
import React from 'react'

import useValidators from '@hooks/useValidators'
import { useTranslation } from '@lib/i18n'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import InputField from '@ui/Input/Field'

const CompanyInvoiceFields = () => {
  const { values } = useFormikContext<CheckoutFormData>()
  const { required, combine, validateVAT } = useValidators()
  const { t } = useTranslation()

  return (
    <>
      <div className="cell-12 cell-md-6 cell-lg-6">
        <Field
          name="invoice.businessName"
          label={t('checkout.invoice.businessName')}
          component={InputField}
          validate={required}
          required
        />
      </div>
      <div className="cell-12 cell-md-6 cell-lg-6">
        <Field
          name="invoice.vatNumber"
          label={t('checkout.invoice.vatNumber')}
          component={InputField}
          validate={combine([required, validateVAT(values.invoice?.country)])}
          required
        />
      </div>
      <div className="cell-12 cell-md-6 cell-lg-6">
        <Field name="invoice.taxCode" label={t('checkout.invoice.taxCode')} component={InputField} />
      </div>
      <div className="cell-6 hidden block-md block-lg" />
    </>
  )
}

export default CompanyInvoiceFields
