import { isAfter, isBefore, isValid } from 'date-fns'
import { isPossiblePhoneNumber } from 'libphonenumber-js/min'
import { postcodeValidator } from 'postcode-validator'

import config from '@config'
import dateUtils from '@lib/date'
import { useTranslation } from '@lib/i18n'
import validationUtils from '@lib/validation'

export type Validator<T = any> = (value: T) => string | undefined

interface UseValidatorsResult {
  combine: (validators: Validator[]) => Validator
  required: Validator
  phone: Validator
  email: Validator
  birthDate: Validator
  date: Validator
  passengers: (value: Passenger.Param[], rpn: number) => string | undefined
  zipcode: (countryCode?: string | null) => Validator
  optionSelectedFactory: <T>(text: string, getOptionText: (option: T) => string | null | undefined) => Validator<T>
  uniq: <T>(anotherValue?: T | null) => Validator
  same: (anotherValue: string | null, message: string) => Validator
  validateVehicle: Validator
  validateMeal: (count: number) => Validator
  validateLength: (length: number, message: string) => Validator
  validateUSPhone: Validator
  validateVAT: (country?: string | null) => Validator
  validateFiscalCode: Validator
}

const getPaxCount = (value: Passenger.Param[], types?: (string | undefined)[]): number =>
  value.reduce((mem, curr) => (types?.includes(curr.type) ? mem + curr.pax : mem), 0)

const useValidators = (): UseValidatorsResult => {
  const { t } = useTranslation()

  const combine =
    (validators: Validator[]): Validator =>
    (value: any) => {
      for (const validate of validators) {
        const error = validate(value)

        if (error) return error
      }
    }

  const validatePhone: Validator = (value?: string) =>
    value && !isPossiblePhoneNumber(value) ? t('errors.phoneInvalid') : undefined

  const validateUSPhone: Validator = (value?: string) => {
    const usPhoneNumberRegex = /^(\+1\s?)?(\(?(?!(684|671|670|787|939|340))([2-9][0-9]{2})\)?)[-.\s]?\d{3}[-.\s]?\d{4}$/

    return value && !usPhoneNumberRegex.test(value) ? t('errors.phoneInvalid') : undefined
  }

  const validateRequired: Validator = value => {
    const emptyValues = [undefined, null, '', false]
    const isError = emptyValues.includes(value)

    return isError ? t('errors.required') : undefined
  }

  const validateEmail: Validator = value => {
    const isMatched = (value as string).match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/)

    return isMatched ? undefined : t('errors.invalid_email')
  }

  const validateDate: Validator = value => {
    const minDate = dateUtils.parse('1900-01-01')
    const date = dateUtils.parse(value)

    return !isValid(date) || isBefore(date, minDate) ? t('errors.invalid_date') : undefined
  }

  const validateBirthDate: Validator = value => {
    if (validateRequired(value)) return validateRequired(value)

    const date = dateUtils.parse(value)

    return validateDate(value) || isAfter(date, new Date()) ? t('errors.invalid_date') : undefined
  }

  const validatePassengerType = (value: Passenger.Param[], rpn: number): string | undefined => {
    const { required, check, relation } = { ...config.passengersValidity.byRpn[rpn] }

    if (!relation) return

    // eslint-disable-next-line no-unsafe-optional-chaining
    const [multiplier, index] = relation?.split(':')
    const requiredCount = getPaxCount(value, required)
    const checkCount = getPaxCount(value, check)

    const underage =
      checkCount * Number(index) > requiredCount * Number(multiplier) ||
      (checkCount > Number(index) && requiredCount < Number(multiplier))

    return underage ? t('errors.underage', { count: Number(index) }) : undefined
  }

  const optionSelectedFactory: UseValidatorsResult['optionSelectedFactory'] = (text, getOptionText) => value =>
    getOptionText(value) !== text ? t('errors.autocomplete.selectOption') : undefined

  const validateZipCode = (countryCode?: string | null) => (value?: string) => {
    const shouldValidate = value != null && countryCode != null && ['US', 'CA'].includes(countryCode)

    if (shouldValidate && !postcodeValidator(value, countryCode)) {
      return t('errors.zipCode')
    }
  }

  const validateUniq =
    <T>(anotherValue?: T | null): Validator<string | null> =>
    value => {
      const compare = anotherValue && Object.values(anotherValue).filter(Boolean).length >= 2
      if (value && compare) return t('errors.uniq')
    }

  const validateSame =
    (otherValue: string | null, message: string): Validator =>
    value => {
      if (value !== otherValue) return message
    }

  const validateVehicle: Validator = (value: Ancillary.Item[]) => {
    const isError = !value.length

    return isError ? t('errors.vehicle') : undefined
  }

  const validateMeal = (count: number) => (value: Ancillary.Item[]) => {
    const isError = value.length !== count

    return isError ? t('errors.meal') : undefined
  }

  const validateVAT = (country?: string | null) => (value: string) => {
    if (!country) return t('errors.invalidCountry')

    const regex = validationUtils.getVATRegex(country)
    if (!regex) return undefined

    const isMatched = regex.test(value)

    return isMatched ? undefined : t('errors.invalidVat')
  }

  const validateFiscalCode = (value: string) => {
    const regex = validationUtils.getFiscalCodeRegex()
    const isMatched = regex.test(value)

    return isMatched ? undefined : t('errors.invalidFiscalCode')
  }

  const validateLength =
    (length: number, message: string): Validator =>
    value => {
      if (value.length !== length) return message
    }

  return {
    required: validateRequired,
    phone: validatePhone,
    email: validateEmail,
    birthDate: validateBirthDate,
    date: validateDate,
    optionSelectedFactory,
    passengers: validatePassengerType,
    zipcode: validateZipCode,
    uniq: validateUniq,
    same: validateSame,
    combine,
    validateVehicle,
    validateMeal,
    validateLength,
    validateUSPhone,
    validateVAT,
    validateFiscalCode,
  }
}

export default useValidators
